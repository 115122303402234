import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";

const RepairPortal = () => (
  <Layout>
    <Seo
      title="Superbase x DKAVS Repair Portal"
      description="Buy Superbase NG - Cross-Platform Database Applications"
    />
    <div>
      <main>
        <div className="container">
          <div className="px-4 pt-5 text-center">
            <h1 className="display-3">Repair Portal</h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">
                The future of self hosted product repair is here, everything
                required to repair in the 21st Century from people who know what
                they're talking about.
              </p>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                <a
                  href="https://www.superbase.com/contact/"
                  className="btn btn-primary btn-lg px-4 me-sm-3"
                >
                  Contact Us
                </a>
                <a
                  href="https://repair-portal.k-c13.org/#/"
                  className="btn btn-outline-secondary btn-lg px-4"
                >
                  Live Preview
                </a>
              </div>
            </div>
          </div>
          <div className="container py-3">
            <h2 className="pb-2 border-bottom">Features</h2>
            <div className="row g-5 py-5">
              <div className="col-md-4 d-flex align-items-start">
                <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                  <svg className="bi" width="1.5em" height="1.5em">
                    <use href="#speedometer2"></use>
                  </svg>
                </div>
                <div>
                  <h2>Tablet & Mobile Friendly</h2>
                  <p>
                    Designed from the start to be Mobile and Tablet friendly, an
                    interface both you and your customers will love.{" "}
                  </p>
                  <a href="#screenshots" className="btn btn-outline-primary">
                    Learn more...
                  </a>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-start">
                <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                  <svg className="bi" width="1.5em" height="1.5em">
                    <use href="#cpu-fill"></use>
                  </svg>
                </div>
                <div>
                  <h2>Easy Setup</h2>
                  <p>
                    Setup to suit your business, whether you are focused on
                    working with just a handful of key clients, or want to
                    accept repairs from members of public without all the hassle
                  </p>
                  <a href="#setup" className="btn btn-outline-primary">
                    Learn More...
                  </a>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-start">
                <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                  <svg className="bi" width="1.5em" height="1.5em">
                    <use href="#toggles2"></use>
                  </svg>
                </div>
                <div>
                  <h2>Expandable</h2>
                  <p>
                    You can add all the features you need, we have components
                    for everything a Repair Business could need: Field Service,
                    Holiday Planning, Full Role Based Access, and more...
                  </p>
                  <a href="#components" className="btn btn-outline-primary">
                    Find out more...
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container py-5" id="screenshots">
            <h2 className="pb-2 border-bottom">Gallery</h2>
            <div
              id="myCarousel"
              className="carousel slide mt-5"
              height="50rem"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide-to="0"
                  className=""
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                  className=""
                ></button>
                <button
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                  className="active"
                  aria-current="true"
                ></button>
                <button
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item">
                  <img
                    src="../../img/alexandre-debieve-FO7JIlwjOtU-unsplash.jpg"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                  <div className="container">
                    <div className="carousel-caption text-start">
                      <h1>Example headline.</h1>
                      <p>
                        Some representative placeholder content for the first
                        slide of the carousel.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src="../../img/christopher-gower-m_HRfLhgABo-unsplash.jpg"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                  <div className="container">
                    <div className="carousel-caption">
                      <h1>Another example headline.</h1>
                      <p>
                        Some representative placeholder content for the second
                        slide of the carousel.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item active">
                  <img
                    src="../../img/lars-kienle-IlxX7xnbRF8-unsplash.jpg"
                    alt=""
                    width="100%"
                    height="100%"
                  />

                  <div className="container">
                    <div className="carousel-caption text-end">
                      <h1>One more for good measure.</h1>
                      <p>
                        Some representative placeholder content for the third
                        slide of this carousel.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src="../../img/alexandre-debieve-FO7JIlwjOtU-unsplash.jpg"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                  <div className="container">
                    <div className="carousel-caption text-start">
                      <h1>Example headline.</h1>
                      <p>
                        Some representative placeholder content for the fourth
                        slide of the carousel.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="container py-5" id="setup">
            <h2 className="pb-2 border-bottom">Running </h2>
          </div>

          <div className="container py-3" id="components">
            <h2 className="pb-2 border-bottom">Components</h2>
            We have full featured components for:
            <h3 className="mt-3">Field Service</h3>
            At the heart of repair are goods, and if these are too big / bulky
            to be shipped automatically with our DPD Integration, the field
            service module is for you allows you to
            <p>
              You simply specify all the addresses you need to visit, these are
              then automatically assigned to a driver who will be guided to the
              property using either Waze or Google Maps
            </p>
            <h3 className="mt-3">Employee Management</h3>
            Managing employee holidays, securely storing contracts and personal
            details can be a massive hastle.
            <p>
              <b>NOT</b> with our Employee Management module, your employees can
              update their Home Address, Mobile Number, and even request days
              off. If you use external services employees can be guided to
              those, meaning you can spend more time on what matters!
            </p>
            <h3 className="mt-3">Customer Repair Portal</h3>
            We are in a 24/7 world, and allowing customers to book in new jobs
            on their time is critical.
            <p>
              We provide a job number and even a shipping label from DPD, you
              just wait for the product to arrive.
            </p>
            <p>
              Once it reaches your workshop the customer can track their repair
              in real time.
            </p>
            <h3 className="mt-3">Partner Portal</h3>
            Be it a retailer, insurance company, or just a large customer, the
            ability to provide custom solutions to your partners is invaluable.
            <p>
              With full Role Based Access a partner can issue their own logins,
              allowing their agents to track and create repairs for their
              customers without you having to be involved.
            </p>
          </div>
        </div>
      </main>
      <svg xmlns="http://www.w3.org/2000/svg" display="none">
        <symbol id="speedometer2" viewBox="0 0 16 16">
          <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"></path>
          <path
            fillRule="evenodd"
            d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
          ></path>
        </symbol>
        <symbol id="cpu-fill" viewBox="0 0 16 16">
          <path d="M6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path>
          <path d="M5.5.5a.5.5 0 0 0-1 0V2A2.5 2.5 0 0 0 2 4.5H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2A2.5 2.5 0 0 0 4.5 14v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14a2.5 2.5 0 0 0 2.5-2.5h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14A2.5 2.5 0 0 0 11.5 2V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5zm1 4.5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3A1.5 1.5 0 0 1 6.5 5z"></path>
        </symbol>
        <symbol id="toggles2" viewBox="0 0 16 16">
          <path d="M9.465 10H12a2 2 0 1 1 0 4H9.465c.34-.588.535-1.271.535-2 0-.729-.195-1.412-.535-2z"></path>
          <path d="M6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm.535-10a3.975 3.975 0 0 1-.409-1H4a1 1 0 0 1 0-2h2.126c.091-.355.23-.69.41-1H4a2 2 0 1 0 0 4h2.535z"></path>
          <path d="M14 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"></path>
        </symbol>
        <symbol id="tools" viewBox="0 0 16 16">
          <path d="M1 0L0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z"></path>
        </symbol>
      </svg>
    </div>
  </Layout>
);

export default RepairPortal;
